<template>
  <a-modal
    :width="800"
    centered
    destroyOnClose
    :title="formModal.title"
    :visible="formModal.open"
    :confirm-loading="loading"
    @ok="onConfirm"
    @cancel="closeModal"
  >
    <a-spin :spinning="loading">
      <a-form-model
          ref="formRef"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4 }"
          :wrapper-col="{ span: 16 }"
      >
        <a-form-model-item prop="number" label="套餐编号">
          <a-input disabled v-model="form.number" />
        </a-form-model-item>
        <a-form-model-item prop="package_name" label="套餐名称">
          <a-input v-model="form.package_name" />
        </a-form-model-item>
        <a-form-model-item prop="production" label="包含名称">
          <a-button type="primary" icon="plus" @click="openProdModal">增加产品</a-button>
        </a-form-model-item>
        <a-table
            :columns="columnsProd"
            :dataSource="prodsList"
            :loading="tableLoading"
            :rowKey="record => record.goods_id"
        >
          <template slot="action" slot-scope="text, record">
            <a-popconfirm title="确定删除吗?" @confirm="onDelete(record.goods_id)">
              <a>删除</a>
            </a-popconfirm>
          </template>
          <template slot="prodNum" slot-scope="text, record">
            <a-input-number :min='1' v-model="record.quantity" />
          </template>
        </a-table>
      </a-form-model>
      <material-select-modal
          ref="material"
          v-model="materialsSelectModalVisible"
          :warehouse="form.team"
          @select="onSelectMaterial"
      />
    </a-spin>
  </a-modal>
</template>

<script>
import { rules, columnsProd } from '@/utils/maps/combo'
import { salePackageDetail, salePackageNumber, addSalePackage, updateSalePackage } from '@/api/sale'

export default {
  name: "EditComboForm",
  components: {
    MaterialSelectModal: () => import("@/components/MaterialSelectModal/index"),
  },
  data() {
    return {
      rules: {
        ...rules,
        production: [{ required: true, validator: this.validatePass, trigger: 'change' }]
      },
      columnsProd,
      form: {},
      record: {},
      prodsList: [],
      realProdsList: [],
      dataSet: [],
      params: {},
      formModal: {},
      loading: false,
      tableLoading: false,
      materialsSelectModalVisible: false
    }
  },
  methods: {
    openProdModal() {
      this.materialsSelectModalVisible = true
      this.form.team = 1
      // 赋值选中的selectedKey和selectedRows
      if (this.realProdsList) {
        const keys = this.realProdsList.reduce((unique, o) => {
          if (o.goods) {
            unique.push(o.goods)
          }
          return unique
        }, [])
        this.$refs.material.setSelectedRowKeys(keys, this.realProdsList)
      } else {
        this.$refs.material.setSelectedRowKeys([], [])
      }
    },
    onSelectMaterial(items) {
      const prods = JSON.parse(JSON.stringify(items))
      // 如果存在已选的产品，则将已选的产品数量赋值给新选中的产品
      if (this.prodsList.length) {
        this.prodsList.forEach(itm => {
          prods.forEach(i => {
            if (i.goods === itm.goods_id) {
              i.totalNums = itm.quantity
            }
          })
        })
      }
      this.realProdsList = prods
      this.prodsList = prods.map(item => {
        return {
          goods_id: item.goods,
          goods_name: item.goods_name,
          goods_number: item.goods_number,
          quantity: item.totalNums || item.quantity,
        }
      })
    },
    validatePass(rule, value, callback) {
      if (!this.prodsList.length) {
        callback(new Error('请至少选择一个产品'))
        return
      }
      callback()
    },
    getList() {
      salePackageDetail(this.params).then(data => {
        const { results = {} } = data
        const prods = JSON.parse(JSON.stringify(results.goodl_list))
        this.realProdsList = JSON.parse(JSON.stringify(results.goodl_list))
        this.form = {
          id: results?.package_info?.id,
          number: results?.package_info?.number,
          package_name: results?.package_info?.package_name
        }
        this.prodsList = prods.map(item => {
          return {
            goods_id: item.goods,
            quantity: item.totalNums || item.quantity,
            goods_number: item.goods_number,
            goods_name: item.goods_name,
          }
        })
        console.log(this.prodsList, '🚀 ~ EditComboForm  line: 83 -----')
      }).finally(() => {
        this.tableLoading = false
      })
    },
    onDelete(goods_id) {
      const index = this.prodsList.findIndex(item => item.goods_id === goods_id)
      const idx = this.realProdsList.findIndex(item => item.id === goods_id)
      if (index > -1) {
        this.prodsList.splice(index, 1)
        this.realProdsList.splice(idx, 1)
        console.log(this.prodsList, this.realProdsList, '🚀 ~ EditComboForm onDelete line: 149 -----')
      }
    },
    editModal(id) {
      this.formModal = {
        open: true,
        title: '编辑套餐',
        type: 'edit',
      }
      this.params = id
      this.tableLoading = true
      this.getList()
    },
    addModal() {
      this.formModal = {
        open: true,
        title: '新建套餐',
        type: 'add'
      }
      this.getNumber()
    },
    async getNumber() {
      const { number } = await salePackageNumber()
      this.form = { ...this.form, number }
    },
    onConfirm() {
      this.$refs.formRef.validate(error => {
        if (!error) {
          return false
        }
        this.loading = true
        console.log(this.prodsList, '🚀 ~ EditComboForm  line: 174 -----')
        const products = this.prodsList.map(item => {
          return {
            goods_id: item.goods_id,
            quantity: item.quantity
          }
        })
        const params = { ...this.form, products }
        switch (this.formModal.type) {
          case 'add':
            this.add(params)
            break
          case 'edit':
            this.edit(params)
            break
          default:
            break
        }
      })
    },
    // TODO 新增 API
    add(params) {
      delete params.id
      addSalePackage(params).then(res => {
        this.$message.success('新建成功')
        this.closeModal()
        this.$emit('ok')
      }).finally(() => {
        this.loading = false
      })
    },
    // TODO 修改 API
    edit(params) {
      const id = params.id
      delete params.id
      updateSalePackage(params, id).then(res => {
        this.$message.success('修改成功')
        this.closeModal()
        this.$emit('ok')
      }).finally(() => {
        this.loading = false
      })
    },
    closeModal() {
      this.formModal.open = false
      this.dataSet = []
      this.prodsList = []
      this.realProdsList = []
      this.params = {}
      this.form = {}
      this.$refs.formRef.resetFields()
    },
  }
}
</script>

<style scoped>

</style>
